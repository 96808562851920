body {
  margin: 0;
  font-family: "Open Sans", sans-serif;
  font-size: 11pt;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
}

.flex-1 {
  flex: 1 1 0px;
}

.profile-picture {
  text-align: center;
  padding: 20px;
}

.profile-picture img {
  width: 180px;
  height: 180px;
  border-radius: 100px;
}

.connect {
  display: block;
  color: black !important;
  text-decoration: none !important;
  margin: 5px 0px;
}

.connect svg {
  font-size: 20px;
}

.connect span {
  margin-left: 10px;
}

.header {
  margin-top: 30px;
  align-items: center;
}

.section {
  padding-top: 30px;
  padding-bottom: 30px;
}

.section.gray {
  background-color: #f0f0f0;
}

.project {
  align-items: center;
  margin-top: 15px;
}

.project .image {
  flex-grow: 0;
  flex-shrink: 0;
}

.project .image img {
  width: 200px;
  height: 200px;
  border-radius: 15px;
  box-shadow: 0px 0px 3px #cccccc;
  max-width: 100%;
  margin: 0 auto;
  display: block;
  object-fit: cover;
}

.project .content ul {
  display: none;
  margin: 0px;
  padding: 0px;
}

.project .content ul li {
  list-style: none;
  display: inline-block;
  padding: 4px 8px;
  border-radius: 5px;
  font-size: 0.9em;
  margin-right: 5px;
  margin-top: 5px;
  background-color: #cccccc;
}

.show-more {
  cursor: pointer;
  text-align: center;
}

.show-more svg {
  font-size: 1.25rem;
}

.career {
  padding-top: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid #cccccc;
}

.career .title {
  font-weight: 500;
  font-size: 1.125rem;
  margin-bottom: 5px;
}

.career .position {
  margin-bottom: 5px;
}

.career .duration {
  margin-right: 10px;
  margin-bottom: 5px;
}

.career .location {
  margin-bottom: 5px;
}

.career svg {
  font-size: 20px;
  margin-right: 5px;
}

.career .role {
  margin-bottom: 5px;
}

.career:first-of-type {
  padding-top: 0px;
}

.career:last-of-type {
  border-bottom: none;
}
